(function () {
  'use strict';

  class Contact {
    constructor(ContactRest, $cookies, $rootScope) {
      this.ContactRest = ContactRest;
      this.$cookies = $cookies;
      this.$rootScope = $rootScope;
    }

    contactIndex(contact = {}, callback = angular.noop) {
      return this.ContactRest.contact().get(contact,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    contactCreate(contact = {}, callback = angular.noop) {
      return this.ContactRest.contact().save({contactus: contact},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    contactDelete(contact = {}, callback = angular.noop) {
      return this.ContactRest.contact().remove(contact,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:Contact
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('Contact', Contact);
}());
